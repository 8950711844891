import React from "react"
import styled from "styled-components"
import IntlText from "../../components/intl-text"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import PageWrapper from "../../components/page-wrapper"
import ProjectList from "../../components/project-list"
import usePreloadImages from "../../hooks/usePreloadImages"
import { breakpoints } from "../../theme"
import "./projects.scss"

const Content = styled.div`
  margin-top: 163px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 105px;
  }
`

const Projects = () => {
  usePreloadImages()

  return (
    <Layout
      pageTitle="Projects"
      title="The software development projects are ours challenges - Sysgarage"
      description="Check out our software development projects of our company has successfully tackled. "
    >
      <PageWrapper>
        <PageHeader
          title={<IntlText id="pages.projects.title" />}
          body={[
            <IntlText type="h2" id="pages.projects.body1" />,
            <IntlText type="h3" id="pages.projects.body2" />,
          ]}
        />
        <Content>
          <ProjectList full />
        </Content>
      </PageWrapper>
    </Layout>
  )
}

export default Projects
