import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { breakpoints } from "../theme"
import { body1_Emphasis, title2_Emphasis } from "./global-styles"

const Wrapper = styled.div`
  margin-bottom: 120px;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 34px;
  padding-left: 20px;
  background-color: ${props => props.backgroundColor};
  overflow: hidden;
  cursor: pointer;
  transition-duration: 0.3s;
  & .gatsby-image-wrapper {
    transition-duration: 0.3s;
    width: calc(100% - 10px);
  }
  &:hover {
    transform: scale(0.97);
    transition-duration: 0.3s;
    & .gatsby-image-wrapper {
      transform: scale(1.04);
      transition-duration: 0.3s;
    }
  }
`

const ProjectName = styled.p`
  ${body1_Emphasis};
  margin-top: 47px;
  margin-bottom: 30px;
`
const ProjectDescription = styled.p`
  ${title2_Emphasis};
  margin-bottom: 30px;
`

const ProjectCard = ({
  title,
  body,
  backgroundColor,
  technologies,
  children,
  speed,
  image,
  ...rest
}) => {
  return (
    <Wrapper {...rest} className="item wow slideInUp">
      <div className="">
        <ImageContainer backgroundColor={backgroundColor}>
          {image}
        </ImageContainer>
      </div>
      <ProjectName>{title}</ProjectName>
      <ProjectDescription>{body}</ProjectDescription>
      <p className="body1">{technologies}</p>
    </Wrapper>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

export default ProjectCard
