import { useEffect } from "react";

const images = [
  require("../images/agitagym/background.png"),
  require("../images/agitagym/config-mobile.png"),
  require("../images/agitagym/config.png"),
  require("../images/agitagym/features-mobile.png"),
  require("../images/agitagym/features.png"),
  require("../images/agitagym/icon.png"),
  require("../images/agitagym/illustration1.png"),
  require("../images/agitagym/illustration2.png"),
  require("../images/agitagym/illustration3.png"),
  require("../images/agitagym/illustration4.png"),
  require("../images/agitagym/illustration5.png"),
  require("../images/agitagym/logo-new.png"),
  require("../images/agitagym/logo-old.png"),
  require("../images/agitagym/logo.png"),
  require("../images/agitagym/nutritional-mobile.png"),
  require("../images/agitagym/nutritional.png"),
  require("../images/agitagym/physical-activity-mobile.png"),
  require("../images/agitagym/physical-activity.png"),
  require("../images/agitagym/scratch.jpg"),
  require("../images/agitagym/screenshot1.png"),
  require("../images/agitagym/screenshot2.png"),
  require("../images/agitagym/screenshot3.png"),
  require("../images/agitagym/screenshot4.png"),
  require("../images/basapp/account-mobile.png"),
  require("../images/basapp/account.png"),
  require("../images/basapp/background.png"),
  require("../images/basapp/config-mobile.png"),
  require("../images/basapp/config.png"),
  require("../images/basapp/dashboard-mobile.png"),
  require("../images/basapp/dashboard.png"),
  require("../images/basapp/emergency-mobile.png"),
  require("../images/basapp/emergency.png"),
  require("../images/basapp/icon-cyb.png"),
  require("../images/basapp/logo-cyb.png"),
  require("../images/basapp/map-mobile.png"),
  require("../images/basapp/map.png"),
  require("../images/basapp/scratch.jpg"),
  require("../images/basapp/screenshot1.png"),
  require("../images/basapp/screenshot2.png"),
  require("../images/basapp/screenshot3.png"),
  require("../images/dermaclinic/background.png"),
  require("../images/dermaclinic/config-mobile.png"),
  require("../images/dermaclinic/config.png"),
  require("../images/dermaclinic/features-mobile.png"),
  require("../images/dermaclinic/features.png"),
  require("../images/dermaclinic/flow.png"),
  require("../images/dermaclinic/logo.png"),
  require("../images/dermaclinic/screenshot1.png"),
  require("../images/dermaclinic/screenshot2.png"),
  require("../images/fudex/account-mobile.png"),
  require("../images/fudex/account.png"),
  require("../images/fudex/background.png"),
  require("../images/fudex/config-mobile.png"),
  require("../images/fudex/config.png"),
  require("../images/fudex/features-mobile.png"),
  require("../images/fudex/features.png"),
  require("../images/fudex/icon-1.png"),
  require("../images/fudex/icon-2.png"),
  require("../images/fudex/icon-3.png"),
  require("../images/fudex/icon-4.png"),
  require("../images/fudex/icon.png"),
  require("../images/fudex/illustration1.png"),
  require("../images/fudex/illustration2.png"),
  require("../images/fudex/illustration3.png"),
  require("../images/fudex/logo-new.png"),
  require("../images/fudex/logo-old.png"),
  require("../images/fudex/logo.png"),
  require("../images/fudex/scratch.jpg"),
  require("../images/fudex/screenshot-1.png"),
  require("../images/fudex/screenshot-2.png"),
  require("../images/fudex/screenshot-3.png"),
  require("../images/fudex/screenshot-4.png"),
  require("../images/fudex/tickets-mobile.png"),
  require("../images/fudex/tickets.png"),
  require("../images/globaltill/background.png"),
  require("../images/globaltill/compare-after-1.png"),
  require("../images/globaltill/compare-after-2.png"),
  require("../images/globaltill/compare-after-3.png"),
  require("../images/globaltill/compare-after-4.png"),
  require("../images/globaltill/compare-after-5.png"),
  require("../images/globaltill/compare-before-1.png"),
  require("../images/globaltill/compare-before-2.png"),
  require("../images/globaltill/compare-before-3.png"),
  require("../images/globaltill/compare-before-4.png"),
  require("../images/globaltill/compare-before-5.png"),
  require("../images/globaltill/content-1.png"),
  require("../images/globaltill/content-2.png"),
  require("../images/globaltill/content-3.png"),
  require("../images/globaltill/content-4.png"),
  require("../images/globaltill/content-5.png"),
  require("../images/globaltill/logo.png"),
  require("../images/globaltill/screenshot-1.png"),
  require("../images/globaltill/screenshot-2.png"),
  require("../images/globaltill/screenshot-3.png"),
  require("../images/globaltill/screenshot-4.png"),
  require("../images/zyn/background.png"),
  require("../images/zyn/compare-after-1.png"),
  require("../images/zyn/compare-before-1.png"),
  require("../images/zyn/content1-mobile.png"),
  require("../images/zyn/content1.png"),
  require("../images/zyn/content2-mobile.png"),
  require("../images/zyn/content3-mobile.jpg"),
  require("../images/zyn/content3.jpg"),
  require("../images/zyn/content4.png"),
  require("../images/zyn/content5-mobile.jpg"),
  require("../images/zyn/content5.jpg"),
  require("../images/zyn/content6.png"),
  require("../images/zyn/logo.png"),
  require("../images/zyn/screenshot1-mobile.png"),
  require("../images/zyn/screenshot1.png"),
  require("../images/zyn/screenshot2-mobile.png"),
  require("../images/zyn/screenshot2.png"),
];

export default function usePreloadImages() {
  useEffect(() => {
    images.forEach((image) => {
      new Image().src = image;
    });
  }, []);
}