import React from "react"
import ProjectCard from "./project-card"
import styled from "styled-components"
import IntlText from "./intl-text"
import { Link } from "gatsby"
import { breakpoints } from "../theme"
import { PROJECT_AGITA_GYM_PAGE, PROJECT_BASAPP_PAGE, PROJECT_DERMACLINIC_PAGE, PROJECT_FUDEX_PAGE, PROJECT_GLOBALTILL_PAGE, PROJECT_ZYN_PAGE } from "../navigation"
import { AGITA_GYM_ALT, BASAPP_ALT, DERMACLINIC_ALT, FUDEX_ALT, GLOBAL_TILL_ALT, ZYN_ALT } from "../constants/Constants"
import { StaticImage } from "gatsby-plugin-image";

const intlPath = "pages.projects"

const Container = styled.div`
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 300px;
  }
`
const Item = styled.div`
  @media (min-width: ${breakpoints.medium}) {
    &:nth-child(2n - 1) {
      margin-top: -200px;
    }
  }
`

const ProjectList = ({ full }) => {
  return (
    <Container>
      <div className="row">
        <Item className="col-md-6">
          <Link to={PROJECT_GLOBALTILL_PAGE} aria-label="GlobalTill">
            <ProjectCard
              title={<IntlText id={`${intlPath}.globaltill.title`} />}
              body={<IntlText id={`${intlPath}.globaltill.body`} />}
              backgroundColor="#1565C0"
              technologies={
                <IntlText id={`${intlPath}.globaltill.technologies`} />
              }
              speed={-4}
              image={
                <StaticImage
                  src="../images/projects-globaltill.png"
                  alt={GLOBAL_TILL_ALT}
                />
              }
            ></ProjectCard>
          </Link>
        </Item>
        <Item className="col-md-6">
          <Link to={PROJECT_ZYN_PAGE} aria-label="ZYN">
            <ProjectCard
              title={<IntlText id={`${intlPath}.zyn.title`} />}
              body={<IntlText id={`${intlPath}.zyn.body`} />}
              backgroundColor="#FF4F17"
              technologies={<IntlText id={`${intlPath}.zyn.technologies`} />}
              speed={-4}
              image={
                <StaticImage
                  src="../images/projects-zyn.png"
                  alt={ZYN_ALT}
                />
              }
            ></ProjectCard>
          </Link>
        </Item>
      </div>
      <div className="row">
        <Item className="col-md-6">
          <Link to={PROJECT_BASAPP_PAGE} aria-label="Basapp">
            <ProjectCard
              title={<IntlText id={`${intlPath}.basapp.title`} />}
              body={<IntlText id={`${intlPath}.basapp.body`} />}
              backgroundColor="#F41B1B"
              technologies={<IntlText id={`${intlPath}.basapp.technologies`} />}
              speed={-4}
              image={
                <StaticImage
                  src="../images/projects-basapp.png"
                  alt={BASAPP_ALT}
                />
              }
            ></ProjectCard>
          </Link>
        </Item>
        <Item className="col-md-6">
          <Link to={PROJECT_AGITA_GYM_PAGE} aria-label="Agita Gym">
            <ProjectCard
              title={<IntlText id={`${intlPath}.agita.title`} />}
              body={<IntlText id={`${intlPath}.agita.body`} />}
              backgroundColor="#3ED1BB"
              technologies={<IntlText id={`${intlPath}.agita.technologies`} />}
              speed={-4}
              image={
                <StaticImage
                  src="../images/projects-agita.png"
                  alt={AGITA_GYM_ALT}
                />
              }
            ></ProjectCard>
          </Link>
        </Item>
      </div>
      {full && (
        <div className="row">
          <Item className="col-md-6">
            <Link to={PROJECT_FUDEX_PAGE} aria-label="Fudex">
              <ProjectCard
                title={<IntlText id={`${intlPath}.fudex.title`} />}
                body={<IntlText id={`${intlPath}.fudex.body`} />}
                backgroundColor="#B90A1E"
                technologies={
                  <IntlText id={`${intlPath}.fudex.technologies`} />
                }
                speed={-4}
                image={
                  <StaticImage
                    src="../images/projects-fudex.png"
                    placeholder="blurred"
                    alt={FUDEX_ALT}
                  />
                }
              ></ProjectCard>
            </Link>
          </Item>
          <Item className="col-md-6">
            <Link to={PROJECT_DERMACLINIC_PAGE} aria-label="DermaClinic">
              <ProjectCard
                title={<IntlText id={`${intlPath}.dermaclinic.title`} />}
                body={<IntlText id={`${intlPath}.dermaclinic.body`} />}
                backgroundColor="#F1417B"
                technologies={
                  <IntlText id={`${intlPath}.dermaclinic.technologies`} />
                }
                speed={-4}
                image={
                  <StaticImage
                    src="../images/projects-dermaclinic.png"
                    alt={DERMACLINIC_ALT}
                  />
                }
              ></ProjectCard>
            </Link>
          </Item>
        </div>
      )}
    </Container>
  )
}

export default ProjectList
